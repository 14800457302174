import { Injectable } from '@angular/core';
import { QaHistoryDsService } from '@app/core/data-services/qa-history-ds/qa-history-ds.service';
import { ActiveOrderQuery, ActiveOrderService } from '@app/core/global-state';
import {
  ActiveProductionOrder,
  CancelProductionOrderEntry,
  EndProductionOrderProductionEntry,
  FinishProductionOrderForManualMachineEntry,
  ManualMachineCheckoutPageMode,
  ProductionOrder,
  ProductionOrderManualModeFinishingDataViewModel,
  ProductionOrderManualModeFinishingValuesEntry,
  ProductionOrderService,
  Quantity,
  RunPhase,
  RunPhaseType,
  RunSubPhaseType,
  SetCurrentRunPhaseEntry,
  StartManualProductionOrderEntry,
  StartProductionOrderEntry
} from 'chronos-core-client';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ActiveOrderDsService } from '@app/core/data-services/active-order-ds/active-order-ds.service';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { AppSettingsService } from 'chronos-shared';

@Injectable({
  providedIn: 'root'
})
export class ActiveOrderPhaseDsService {
  constructor(
    private activeOrderService: ActiveOrderService,
    private appSettingsService: AppSettingsService,
    private activeOrderQuery: ActiveOrderQuery,
    private productionOrderService: ProductionOrderService,
    private qaHistoryDsService: QaHistoryDsService,
    private activeOrderDsService: ActiveOrderDsService,
    private activeWorkCenterService: ActiveWorkCenterService
  ) {}

  public enterRunPhase(): Observable<null> {
    return this.setNewActiveOrderPhase(RunPhaseType.RUN, RunSubPhaseType.RUN);
  }

  public enterFinishingPhase(): Observable<null> {
    return this.setNewActiveOrderPhase(RunPhaseType.FINISHING, RunSubPhaseType.FINISHING_OVERVIEW);
  }

  public enterSetupPhase(productionOrder: ProductionOrder, startTime: string, manualTransport: boolean): Observable<ActiveProductionOrder> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: StartProductionOrderEntry = {
      productionOrderStartTime: startTime,
      workCenterId,
      manualTransport
    };
    const params: ProductionOrderService.StartProductionOrderParams = {
      productionOrderId: productionOrder.productionOrderId,
      entry
    };
    return this.productionOrderService
      .startProductionOrder(params)
      .pipe(switchMap(() => this.activeOrderDsService.getActiveProductionOrder()));
  }

  public enterSetupPhaseWithAutomaticPeriodSelection(
    productionOrder: ProductionOrder,
    manualTransport: boolean
  ): Observable<ActiveProductionOrder> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: StartProductionOrderEntry = {
      workCenterId,
      manualTransport
    };
    const params: ProductionOrderService.StartProductionOrderWithAutomaticPeriodSelectionParams = {
      productionOrderId: productionOrder.productionOrderId,
      entry
    };
    return this.productionOrderService
      .startProductionOrderWithAutomaticPeriodSelection(params)
      .pipe(switchMap(() => this.activeOrderDsService.getActiveProductionOrder()));
  }

  public cancelActiveOrder(): Observable<null> {
    const activeOrderId = this.activeOrderQuery.getActiveOrderId();
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: CancelProductionOrderEntry = {
      workCenterId
    };
    const params: ProductionOrderService.CancelProductionOrderParams = {
      productionOrderId: activeOrderId,
      entry
    };
    return this.productionOrderService.cancelProductionOrder(params).pipe(
      tap(() => {
        this.removeActiveOrder();
      })
    );
  }

  public removeActiveOrder(): void {
    this.activeOrderService.reset();
  }

  public selectActiveOrderPhase(): Observable<RunPhaseType> {
    return this.activeOrderQuery.activeOrderPhase$;
  }

  public selectActiveOrderSubPhase(): Observable<RunSubPhaseType> {
    return this.activeOrderQuery.activeOrderSubPhase$;
  }

  public getActiveOrderSubPhase(): RunSubPhaseType {
    return this.activeOrderQuery.getActiveOrderSubPhase();
  }

  public getKpisConfirmed(): Observable<boolean> {
    return this.activeOrderQuery.areKpisConfirmed$;
  }

  public setNewActiveOrderPhase(newPhase: RunPhaseType, newSubPhase: RunSubPhaseType): Observable<null> {
    const activeOrderId = this.activeOrderQuery.getActiveOrderId();

    return this.setOrderPhaseByOrderId(activeOrderId, newPhase, newSubPhase);
  }

  private setOrderPhaseByOrderId(orderId: number, newPhase: RunPhaseType, newSubPhase: RunSubPhaseType): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: SetCurrentRunPhaseEntry = {
      runSubPhaseType: newSubPhase,
      workCenterId
    };
    const params: ProductionOrderService.SetCurrentRunSubPhaseParams = {
      entry,
      productionOrderId: orderId
    };
    return this.productionOrderService.setCurrentRunSubPhase(params).pipe(
      tap(() => {
        this.activeOrderService.setActiveOrderPhase(newPhase, newSubPhase);
      })
    );
  }

  public getFirstSetupPhase(productionOrderId: number): Observable<RunPhase> {
    return this.productionOrderService.getRunPhases(productionOrderId).pipe(
      tap((phases) => {
        this.setSubPhaseStatus(phases);
      }),
      map((phases) => phases[0])
    );
  }

  public setSubPhaseStatus(phases: RunPhase[]): void {
    this.appSettingsService.setPatternInfoSetup(phases.some((phase) => this.isPatternInfoSubPhase(phase)));
    this.appSettingsService.setLineClearanceSetup(phases.some((phase) => this.isLineClearanceSubPhase(phase)));
    this.appSettingsService.setEnableToolCheckout(phases.some((phase) => this.isToolCheckoutSubPhase(phase)));
    this.qaHistoryDsService.setQaHistoryStatusForSetup(phases.some((phase) => this.isQaHistorySubPhase(phase)));
    this.qaHistoryDsService.setQaChecksStatusForSetup(phases.some((phase) => this.isQaChecksSubPhase(phase)));
  }

  public endOrderInAutomaticMode(isInterrupted: boolean): Observable<ActiveProductionOrder> {
    const activeOrderId = this.activeOrderQuery.getActiveOrderId();
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: EndProductionOrderProductionEntry = {
      isInterrupted,
      workCenterId
    };
    const params: ProductionOrderService.EndProductionOrderProductionParams = {
      entry,
      productionOrderId: activeOrderId
    };
    return this.productionOrderService
      .endProductionOrderProduction(params)
      .pipe(switchMap(() => this.activeOrderDsService.getActiveProductionOrder()));
  }

  public startOrderInManualMode(
    productionOrderId: number,
    orderStartTime: Date,
    quantityPerPallet?: Quantity
  ): Observable<ActiveProductionOrder> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: StartManualProductionOrderEntry = {
      productionOrderStartTime: orderStartTime.toISOString(),
      quantityPerPallet,
      workCenterId
    };
    const params: ProductionOrderService.StartProductionOrderManualMachineParams = {
      entry,
      productionOrderId
    };
    return this.productionOrderService.startProductionOrderManualMachine(params).pipe(
      switchMap(() => this.activeOrderDsService.getActiveProductionOrder()),
      tap(() => {
        this.activeOrderService.setActiveOrderPhase(RunPhaseType.RUN, RunSubPhaseType.RUN);
      })
    );
  }

  public endOrderInManualMode(
    productionOrderId: number,
    orderStartTime: string,
    orderEndTime: string,
    isInterrupted: boolean
  ): Observable<ActiveProductionOrder> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: FinishProductionOrderForManualMachineEntry = {
      startTime: orderStartTime,
      endTime: orderEndTime,
      isInterrupted,
      workCenterId
    };
    const params: ProductionOrderService.EndProductionForManualMachineParams = {
      entry,
      productionOrderId
    };
    return this.productionOrderService.endProductionForManualMachine(params).pipe(
      switchMap(() => this.activeOrderDsService.getActiveProductionOrder()),
      switchMap(() => this.enterFinishingPhase())
    );
  }

  public getManualModeFinishingData(productionOrderId: number): Observable<ProductionOrderManualModeFinishingDataViewModel> {
    return this.productionOrderService.getManualModeFinishingData(productionOrderId);
  }

  public setManualSortingValues(
    manualMachineCheckoutPageMode: ManualMachineCheckoutPageMode,
    productionOrderId: number,
    numberOfWorkers: number,
    duration: string
  ): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: ProductionOrderManualModeFinishingValuesEntry = {
      manualMachineCheckoutPageMode,
      duration,
      numberOfWorkers,
      workCenterId
    };
    const params: ProductionOrderService.SaveManualModeFinishingValuesParams = {
      entry,
      productionOrderId
    };
    return this.productionOrderService.saveManualModeFinishingValues(params);
  }

  private isPatternInfoSubPhase(phase: RunPhase): boolean {
    return phase.runSubPhaseType === RunSubPhaseType.SETUP_PATTERN_INFO;
  }
  private isQaHistorySubPhase(phase: RunPhase): boolean {
    return phase.runSubPhaseType === RunSubPhaseType.SETUP_QA_HISTORY;
  }
  private isLineClearanceSubPhase(phase: RunPhase): boolean {
    return phase.runSubPhaseType === RunSubPhaseType.SETUP_LINE_CLEARANCE;
  }
  private isQaChecksSubPhase(phase: RunPhase): boolean {
    return phase.runSubPhaseType === RunSubPhaseType.SETUP_IN_PROGRESS_FINISHING_CHECK;
  }
  private isToolCheckoutSubPhase(phase: RunPhase): boolean {
    return phase.runSubPhaseType === RunSubPhaseType.FINISHING_TOOL_CHECKOUT;
  }
}
