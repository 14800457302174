<main class="speed-monitor-container" #SpeedMonitor>
  <div class="parent-container">
    <section *ngIf="mode === 'Large'" class="machine-description">
      {{ workCenter.workCenterId }} - {{ workCenter.workCenterName }}
      <ng-container *ngIf="!isTvMode">
        <div *ngIf="!isFullScreen" class="to-right">
          <a (click)="fullscreen()">{{ 'SPEED_MONITOR.FULLSCREEN' | translate }}</a>
        </div>
        <div *ngIf="!isFullScreen" class="widget-view-to-right">
          <a (click)="redirectToFullscreen()">
            {{ 'SPEED_MONITOR.FULLSCREEN' | translate }}
          </a>
        </div>
      </ng-container>
    </section>
    <section class="machine-details">
      <ng-container *ngIf="!isDisplayInfo">
        <lib-run-mode
          class="child-container"
          [speed]="speed"
          [valuePropertyBag]="valuePropertyBag"
          [kpiValueBag]="kpiValueBag"
          *ngIf="isRunMode"
        ></lib-run-mode>
        <lib-downtime-mode
          class="child-container"
          [valuePropertyBag]="valuePropertyBag"
          [machineData]="machineData"
          [deltaTime]="deltaTime"
          [state]="workCenter.state"
          [kpiValueBag]="kpiValueBag"
          *ngIf="isDowntimeMode"
        ></lib-downtime-mode>
        <lib-setup-mode
          class="child-container"
          [valuePropertyBag]="valuePropertyBag"
          [machineData]="machineData"
          [deltaTime]="deltaTime"
          [state]="workCenter.state"
          [kpiValueBag]="kpiValueBag"
          *ngIf="isSetupMode"
        ></lib-setup-mode>
        <lib-idle-mode
          class="child-container"
          [valuePropertyBag]="valuePropertyBag"
          [machineData]="machineData"
          [deltaTime]="deltaTime"
          [state]="workCenter.state"
          [kpiValueBag]="kpiValueBag"
          *ngIf="isIdleMode"
        ></lib-idle-mode>
      </ng-container>
      <lib-display-information class="child-container" *ngIf="isDisplayInfo"></lib-display-information>
    </section>
  </div>
</main>
