<section class="shift-report">
  <div class="page-padding">
    <div class="shift-report__container header">
      <h1 class="header__title">{{ 'SHIFT_REPORT.TITLE' | translate }}</h1>
      <lib-dropdown
        [inlineStyle]="{ width: '300px' }"
        [options]="dropdownOptions$ | async"
        [grouped]="true"
        [ngModel]="selectedShiftId$ | async"
        (ngModelChange)="changeShiftReport($event)"
      ></lib-dropdown>
      <app-search-bar
        class="header__search-bar"
        (searchBarValueChanged)="filterByProductionOrder($event)"
        [searchBarPlaceholder]="'SHIFT_REPORT.SEARCH_BAR_PLACEHOLDER' | translate"
      ></app-search-bar>
    </div>
    <div class="shift-report__container kpi">
      <ng-container *ngFor="let shiftHeaderKpi of shiftReportHeaderKpis$ | async" [ngSwitch]="shiftHeaderKpi.kpiIdentifier">
        <app-status-panel
          *ngSwitchCase="KPI_IDENTIFIER.KPI_ORDERS"
          class="kpi__item"
          [label]="'SHIFT_REPORT.KPI_ORDERS' | translate"
          [value]="shiftHeaderKpi.value.value + ' ' + ('APPROVE_REPORT.OF' | translate) + ' ' + shiftHeaderKpi.target.value"
          height="small"
        ></app-status-panel>
        <app-status-panel
          *ngSwitchCase="KPI_IDENTIFIER.GOOD_QUANTITY"
          class="kpi__item"
          [label]="'SHIFT_REPORT.KPI_GOOD_QUANTITY' | translate"
          [value]="(shiftHeaderKpi.value | formatQuantity : false : 0) + ' / ' + (shiftHeaderKpi.target | formatQuantity : false : 0)"
          [unitId]="shiftHeaderKpi.value.unitId"
          height="small"
        ></app-status-panel>
        <app-status-panel
          *ngSwitchCase="KPI_IDENTIFIER.OUTPUT_2"
          class="kpi__item"
          [label]="'SHIFT_REPORT.KPI_OUTPUT' | translate"
          [value]="shiftHeaderKpi.value | formatQuantity : false : 0"
          [unitId]="shiftHeaderKpi.value.unitId"
          height="small"
        ></app-status-panel>
        <app-status-panel
          *ngSwitchCase="KPI_IDENTIFIER.DOWNTIME"
          class="kpi__item"
          [label]="'SHIFT_REPORT.KPI_DOWNTIME' | translate"
          [value]="shiftHeaderKpi.valueTime | elapsedTime"
          height="small"
        ></app-status-panel>
      </ng-container>
    </div>
    <div class="shift-report__container header">
      <h1 class="header__title">{{ 'SHIFT_REPORT.OPEN_TASKS_TITLE' | translate }}</h1>
    </div>
    <div class="shift-report__container open-tasks">
      <ng-container *ngFor="let shiftReportOpenTask of shiftReportOpenTasks$ | async">
        <div
          [ngClass]="shiftReportOpenTask.type === 'EmployeeRegistration' ? 'cursor-clickable' : 'cursor-nonclickable'"
          (click)="onOpenTaskKPIClick(shiftReportOpenTask.type)"
        >
          <app-task-item
            [badgeNumber]="shiftReportOpenTask.hasViolation ? shiftReportOpenTask.openNumberOfTasks : 0"
            [title]="'SHIFT_REPORT.OPEN_TASK_' + shiftReportOpenTask.type | uppercase | translate"
            [value]="shiftReportOpenTask.closedNumberOfTasks + '/' + shiftReportOpenTask.totalNumberOfTasks"
          ></app-task-item>
        </div>
      </ng-container>
    </div>
    <div class="shift-report__container--list">
      <div class="shift-report__container">
        <ng-container *ngFor="let shiftReportItem of shiftReportNotOpenItems$ | async">
          <app-order-item [shiftReportItem]="shiftReportItem" [isDowntime]="isDowntimeFlag">
            <app-split-button class="shift-report__action" [items]="createButtonValues(shiftReportItem)"></app-split-button>
          </app-order-item>
        </ng-container>
      </div>
      <div class="shift-report__line"></div>
      <div class="shift-report__container">
        <ng-container *ngFor="let shiftReportItem of shiftReportOpenItems$ | async">
          <app-order-item [shiftReportItem]="shiftReportItem">
            <app-split-button class="shift-report__action" [items]="createButtonValues(shiftReportItem)"></app-split-button>
          </app-order-item>
        </ng-container>
      </div>
    </div>
  </div>
  <footer class="shift-report__footer">
    <ng-container *ngIf="shiftReportAvailable$ | async; else previewReport">
      <ng-container *ngIf="hasUncompletedTask$ | async; else allTasksCompleted">
        <app-setup-footer [nextCommand]="sendReportCommand" [confirmFirstCheckboxCommand]="confirmCheckboxCommand"></app-setup-footer>
      </ng-container>
      <ng-template #allTasksCompleted>
        <app-setup-footer
          [nextCommand]="sendCompleteReportCommand"
          [confirmFirstCheckboxCommand]="confirmCheckboxCommand"
        ></app-setup-footer>
      </ng-template>
    </ng-container>
    <ng-template #previewReport>
      <div class="footer-link">
        <i class="icon icon-info-solid"></i>
        <span>
          {{ 'SHIFT_REPORT.SHIFT_CONFIRMED' | translate }},
          <span class="link-text" (click)="viewShiftReport()">{{ 'SHIFT_REPORT.SHIFT_NOTE' | translate }}</span>
        </span>
      </div>
    </ng-template>
  </footer>
</section>
