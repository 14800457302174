import { Component, Input, Output, EventEmitter } from '@angular/core';
import { HierarchyLevel } from '@app/modules/resource-configuration/models';

@Component({
  templateUrl: './hierarchy-header.component.html',
  styleUrls: ['./hierarchy-header.component.scss']
})
export class HierarchyComponent {
  @Input() public hierarchyLevel: HierarchyLevel[] = [];
  @Input() public level: number;
  @Input() public isDisabled: boolean;

  @Output() public workCenterChange = new EventEmitter<{ level: number; entity: any }>();

  public getLevel(level: number, entity: HierarchyLevel) {
    this.hierarchyLevel.forEach((row, index) =>
      entity.name === row.name ? (this.hierarchyLevel[index].isActive = true) : (this.hierarchyLevel[index].isActive = false)
    );
    this.workCenterChange.emit({ level, entity });
  }
}
