<div class="row pb-10">
  <div role="group" class="p-selectbutton p-buttonset p-component" [ngClass]="{ disabled: isDisabled }">
    <div
      role="button"
      class="{{ entity.isActive === true ? 'p-button p-component active-button' : 'p-button p-component' }}"
      (click)="getLevel(level, entity)"
      *ngFor="let entity of hierarchyLevel; index as i"
    >
      <span *ngIf="i === 0">{{ 'RESOURCE_CONFIGURATION.ALL' | translate }}</span>
      <span *ngIf="i !== 0">{{ entity.name }}</span>
    </div>
  </div>
</div>
