<section class="modal-dialog-box">
  <div class="row pb15">
    <div class="col-2 setting_detail_label">{{ 'RESOURCE_CONFIGURATION.NAME' | translate }}</div>
    <div class="col-4 setting_detail_value">{{ setting.settingName }}</div>
    <div class="col-2 setting_detail_label">{{ 'RESOURCE_CONFIGURATION.TENANT' | translate }}</div>
    <div class="col-4 setting_detail_value">{{ tenantName }}</div>
    <div class="col-2 setting_detail_label">{{ 'RESOURCE_CONFIGURATION.CURRENT_VALUE' | translate }}</div>
    <div class="col-4 setting_detail_value">{{ getCurrentSettingValue() }}</div>
    <div class="col-2 setting_detail_label">{{ 'RESOURCE_CONFIGURATION.WORK_CENTER_GROUP' | translate }}</div>
    <div class="col-4 setting_detail_value">{{ workCenterGroupName }}</div>
    <div class="col-2 setting_detail_label">{{ 'RESOURCE_CONFIGURATION.DEFINED_AT_LEVEL' | translate }}</div>
    <div class="col-4 setting_detail_value">{{ definedAtHierarchyLevel }}</div>
    <div class="col-2 setting_detail_label">{{ 'RESOURCE_CONFIGURATION.WORK_CENTER' | translate }}</div>
    <div class="col-4 setting_detail_value">{{ workCenterName }}</div>
  </div>
  <p-table [value]="value" [columns]="COLUMNS" [scrollable]="true" [scrollHeight]="scrollHeight">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <ng-container *ngFor="let column of columns">
          <th [style.width]="column.width">{{ column.label | translate }}</th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr>
        <td [style.width]="'140px'">
          <div class="table-text">{{ row.hierarchyLevel }}</div>
        </td>
        <td [style.width]="'140px'">
          <div class="table-text">{{ getHistorySettingValue(row, 'previous') }}</div>
        </td>
        <td [style.width]="'140px'">
          <div class="table-text">{{ getHistorySettingValue(row, 'current') }}</div>
        </td>
        <td [style.width]="'140px'">
          <div class="table-text">{{ row.updateBy }}</div>
        </td>
        <td [style.width]="'140px'">
          <div class="table-text">{{ row.updateTime | utcToLocal | date : 'dd.MM.yyyy HH:mm' }}</div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [colSpan]="columns.length">
          {{ 'DOCUMENTS.NO_INFORMATION' | translate }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</section>

<lib-modal-footer [submitLabel]="'MODAL_FOOTER.CLOSE'" (submitClicked)="onCloseModal()" [showDeclineButton]="false"></lib-modal-footer>
