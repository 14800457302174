<div class="idle-top-background-color upper-part">
  <label class="labelText">{{ 'SPEED_MONITOR.IDLE' | translate }}</label>
  <div
    class="value"
    *ngIf="valuePropertyBag.duration"
    [ngClass.xs]="{ value: false, 'value-mobile': true }"
    [ngClass.sm]="{ value: false, 'value-mobile': true }"
  >
    {{ valuePropertyBag.duration | millisecondsToDuration }}
  </div>
  <div class="kpi">
    <div class="kpi-label"></div>
    <div class="kpi-value"></div>
  </div>
</div>
<div class="bottom-background-color lower-part">
  <div class="labelText hidden">
    {{ this.targetText }}
  </div>
  <div
    class="target-value"
    [ngClass.xs]="{ 'target-value': false, 'target-value-mobile': true }"
    [ngClass.sm]="{ 'target-value': false, 'target-value-mobile': true }"
  >
    {{ targetIdletime }}
  </div>
  <div *ngIf="currentKpi && currentKpi.name && valuePropertyBag.mode === 'Large'" class="kpi">
    <div class="lower-kpi-label">
      {{ currentKpi.name }}
    </div>
    <div class="lower-kpi-value">
      <label *ngIf="currentKpi">
        <span *ngIf="currentKpi.value">{{ currentKpi.value | formatQuantity : false }}</span>
        <span *ngIf="currentKpi.valueTime">{{ currentKpi.valueTime }}</span>
      </label>
    </div>
  </div>
</div>
