import { Component, OnInit } from '@angular/core';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { SpeedMonitorViewMode } from 'chronos-shared';

@Component({
  selector: 'app-speed-monitor-host',
  templateUrl: './speed-monitor-host.component.html',
  styleUrls: ['./speed-monitor-host.component.scss']
})
export class SpeedMonitorHostComponent implements OnInit {
  public screenMode: SpeedMonitorViewMode = SpeedMonitorViewMode.Large;
  public workCenterId: number;

  constructor(private activeWorkCenterService: ActiveWorkCenterService) {}

  public ngOnInit(): void {
    this.workCenterId = this.activeWorkCenterService.getWorkCenterId();
  }
}
