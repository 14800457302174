import { AfterViewInit, ChangeDetectorRef, Component, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ProductionOrderSetupPhaseState, WorkCenterService } from 'chronos-core-client';
import { TelemetryQueryDto, TelemetryService, TelemetryValueDto } from 'chronos-panda-client';
import moment from 'moment-mini';
import { Observable } from 'rxjs';
import { ChartType, LoadingNotificationService, CounterHelper, MachineChartComponent, LogService } from 'chronos-shared';
import { notificationTopic } from '@app/shared/utils';
import { finalize } from 'rxjs/operators';
import { Quantity } from 'projects/chronos-core-client/src/public-api';
import { ApproveDsService, ProductionOrderDsService, DowntimeDsService, MachineScheduleDsService } from '@app/core/data-services';

export enum ChartMode {
  EditSetup,
  EditSetupApprove,
  SplitDowntime,
  EditDowntime
}

export interface IMachineChartDialogConfiguration {
  chartMode: ChartMode;

  // common configuration
  workCenterId: number;
  productionOrderId: number;
  runId?: number;
  startTime: Date;
  endTime?: Date;
  quantity: Quantity;

  // split downtime related configuration
  downTimeId?: number;

  // approval related configuration
  approvalReportLineId?: number;
  approvalReportDate?: string;
  waste?: Quantity;
  maculature?: Quantity;

  // edit downtime related configuration
  startCounter?: number;
  endCounter?: number;
  latestEndTime?: Date;
}

@Component({
  selector: 'app-machine-chart-dialog',
  templateUrl: './machine-chart-dialog.component.html',
  styleUrls: ['./machine-chart-dialog.component.scss']
})
export class MachineChartDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(MachineChartComponent) machineChartComponent: MachineChartComponent;

  public readonly INPUT_STYLE = { textAlign: 'right' };

  public ChartMode = ChartMode;
  public earliestLimit?: Date;
  public latestLimit?: Date;
  public latestEventTime: Date;
  public workCenterId: number;
  public startTime: Date;
  public endTime: Date;
  public splitTime: Date;
  public formGroup: UntypedFormGroup;
  public editedExternalOrderId: string;
  public productionOrderId: number;
  public isStartTimeEditable = true;
  public isEndTimeEditable = true;
  public start$: Observable<Date>;
  public externalWorkCenterID: string;
  public isDisplayChart = true;
  public chartName: string;
  public waste: Quantity = { value: 0, unitId: '' };
  public maculature: Quantity = { value: 0, unitId: '' };
  public quantity: Quantity = { value: 0, unitId: '' };
  public approvalReportLineId: number;
  public approvalReportDate: string;
  public readonly LOADING_TOPIC = notificationTopic.modalEditSetup;
  public readonly LOADING_TOPIC_EDITDOWNTIME = notificationTopic.editDowntime;
  private isEndTimeChanging = false;
  public runId: number;
  public previousQuantity: number;
  public earliestSetupStartCounter: number;
  public endCounter: number;
  public startCounter: number;
  public orderSetupPhaseState: ProductionOrderSetupPhaseState;
  public submitDisabled = false;
  public chartMode: ChartMode;
  public minFractionDigits: number = null;
  public maxFractionDigits: number = null;
  public unitId: string;

  private counterCoefficient: number;
  private initialQuantity: number;
  private initialStartTime: Date;
  private initialEndTime: Date;
  private initialStartCounter: number;
  private initialEndCounter: number;
  private isCalSetupQuant = true;
  private downtimeId: number;
  private setupQuantityData: TelemetryValueDto[] = [];
  private readonly PANDA_DATA_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSSSSS[Z]';

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private formBuilder: UntypedFormBuilder,
    private telemetryService: TelemetryService,
    private workCenterService: WorkCenterService,
    private cd: ChangeDetectorRef,
    private productionOrderDsService: ProductionOrderDsService,
    private approveDsService: ApproveDsService,
    private downtimeDsService: DowntimeDsService,
    private machineScheduleDsService: MachineScheduleDsService,
    @Inject(LOCALE_ID) public locale: string
  ) {}

  public ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  public ngOnInit(): void {
    this.initializeFormGroup();
    this.parseConfigData();
    this.loadSetupQuantityData();
    this.isSubmitDisabled();
  }

  private initializeFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      startDate: [''],
      setupQuantity: [''],
      waste: [''],
      maculature: [''],
      splitDateTime: [''],
      endDate: ['']
    });
  }

  private getData(): IMachineChartDialogConfiguration {
    return this.config.data;
  }

  private parseConfigData(): void {
    const data = this.getData();
    if (data) {
      this.chartMode = data.chartMode;

      this.approvalReportLineId = data.approvalReportLineId;
      this.approvalReportDate = data.approvalReportDate;
      this.workCenterId = data.workCenterId;
      this.startTime = this.toUtc(data.startTime);
      this.endTime = data.endTime === null || data.endTime === undefined ? this.toUtc(new Date()) : this.toUtc(data.endTime);
      this.waste = { ...data.waste };
      this.maculature = { ...data.maculature };
      this.quantity = { ...data.quantity };
      this.initialQuantity = data.quantity?.value;
      this.initialStartTime = this.startTime;
      this.initialEndTime = this.endTime;
      this.initialStartCounter = data.startCounter;
      this.initialEndCounter = data.endCounter;
      this.splitTime = this.endTime;
      this.formGroup.controls.startDate.setValue(this.startTime);
      this.formGroup.controls.setupQuantity.setValue(this.quantity?.value);
      this.formGroup.controls.endDate.setValue(this.endTime);
      this.formGroup.controls.splitDateTime.setValue(this.endTime);
      this.formGroup.controls.waste.setValue(this.waste);
      this.formGroup.controls.maculature.setValue(this.maculature);
      this.chartName = ChartType.editSetup;
      this.previousQuantity = this.quantity?.value;

      switch (this.chartMode) {
        case ChartMode.EditSetup:
          this.waste = { value: 0, unitId: this.quantity?.unitId };
          this.maculature = { value: 0, unitId: this.quantity?.unitId };
          this.getActiveProductionOrderId();
        // fall through to edit setup approve

        case ChartMode.EditSetupApprove:
          this.productionOrderId = data.productionOrderId;
          this.runId = data.runId;
          this.getSetupPhaseLimit(this.productionOrderId);
          break;
        case ChartMode.SplitDowntime:
          this.earliestLimit = this.startTime;
          this.latestLimit = this.endTime;
          break;
        case ChartMode.EditDowntime:
          this.earliestLimit = this.startTime;
          this.latestLimit = moment.utc(data.latestEndTime).toDate();
          this.startCounter = data.startCounter;
          this.endCounter = data.endCounter;
          this.counterCoefficient = CounterHelper.GetCounterCoefficient(this.quantity.unitId);
          this.getActiveProductionOrderId();
          break;
        default:
          throw new Error(`Invalid chart mode ${this.chartMode}`);
      }

      this.isStartTimeEditable =
        this.chartMode === ChartMode.EditDowntime ||
        this.chartMode === ChartMode.EditSetup ||
        this.chartMode === ChartMode.EditSetupApprove;
      this.isEndTimeEditable =
        this.chartMode === ChartMode.EditDowntime ||
        this.chartMode === ChartMode.EditSetup ||
        this.chartMode === ChartMode.EditSetupApprove;

      if (this.chartMode === ChartMode.EditDowntime || this.chartMode === ChartMode.SplitDowntime) {
        this.commonFieldsForDowntime(data);
      }
    }
  }

  public loadSetupQuantityData(): void {
    const diffInTime = this.endTime.getTime() - this.startTime.getTime();
    const diffInDays = diffInTime / (1000 * 3600 * 24);
    const endTimeHours = this.chartMode === ChartMode.EditDowntime ? 3 : diffInDays > 2 ? 8 : 1;

    this.workCenterService.getWorkCenter(this.workCenterId).subscribe((workCenterDetails) => {
      if (workCenterDetails) {
        this.externalWorkCenterID = workCenterDetails.externalWorkCenterId;
        this.unitId = workCenterDetails.counterUnitId;
        const setupQuantityQuery: TelemetryQueryDto = {
          wellKnownSignal: 'counter_qty',
          resource: this.externalWorkCenterID,
          from: moment.utc(this.startTime).add(-1, 'hours').format(this.PANDA_DATA_FORMAT),
          to:
            this.endTime != null
              ? moment.utc(this.endTime).add(endTimeHours, 'hours').format(this.PANDA_DATA_FORMAT)
              : moment.utc().add(1, 'hours').format(this.PANDA_DATA_FORMAT),
          aggFunction: '',
          groupBy: '',
          resultPoints: ''
        };
        if (!this.submitDisabled) {
          this.telemetryService.apiTelemetryPost({ body: setupQuantityQuery }).subscribe((result) => {
            this.setupQuantityData = result.telemetryData;
          });
        }
      }
    });
  }

  public onDecline(): void {
    this.ref.close(false);
  }

  public onSubmit(): void {
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);

    switch (this.chartMode) {
      case ChartMode.EditSetup:
        this.editSetup();
        break;
      case ChartMode.EditSetupApprove:
        this.editSetupApproval();
        break;
      case ChartMode.SplitDowntime:
        this.updateSplitDownTime();
        break;
      case ChartMode.EditDowntime:
        this.updateEditDowntime();
        break;
      default:
        throw new Error(`Invalid chart mode ${this.chartMode}`);
    }
  }

  public clearTimeToMinutes(date: Date): Date {
    return moment(date).seconds(0).milliseconds(0).toDate();
  }

  private toUtc(time: Date): Date {
    return moment.utc(time).toDate();
  }

  public getActiveProductionOrderId(): void {
    this.machineScheduleDsService.getActiveProductionOrder().subscribe((activeProductionOrder) => {
      if (activeProductionOrder) {
        this.editedExternalOrderId = activeProductionOrder.externalProductionOrderId;
        this.productionOrderId = activeProductionOrder.productionOrderId;
        this.runId = activeProductionOrder.runId;
        if (this.chartMode === ChartMode.EditSetup || this.chartMode === ChartMode.EditSetupApprove) {
          this.getSetupPhaseLimit(this.productionOrderId);
        }
      }
    });
  }

  public getSetupPhaseLimit(productionOrderId: number): void {
    if (productionOrderId === undefined || productionOrderId === null || productionOrderId === 0) {
      return;
    }

    const calledFromApproveReport = !!this.approvalReportDate;

    this.productionOrderDsService
      .getSetupPhaseState(productionOrderId, this.runId, calledFromApproveReport)
      .subscribe((setupPhaseState) => {
        this.counterCoefficient = CounterHelper.GetCounterCoefficient(setupPhaseState.setupPhaseQuantity.unitId);

        // Note: the commented lines below are passed in to the dialog from the caller, this is especially important for approve
        //       in approve the caller passes in the setup start and end time based on the limits of the approve day
        //       in the future the view should be adjusted to return the correct data
        this.editedExternalOrderId = setupPhaseState.externalProductionOrderId;
        this.earliestSetupStartCounter = setupPhaseState.earliestSetupStartCounter;
        this.earliestLimit = setupPhaseState.earliestSetupStart ? new Date(setupPhaseState.earliestSetupStart) : null;
        this.latestLimit = setupPhaseState.latestSetupEnd ? new Date(setupPhaseState.latestSetupEnd) : null;
        this.latestEventTime = new Date(setupPhaseState.latestEventTime);
        this.startCounter = this.chartMode === ChartMode.EditDowntime ? this.config.data?.startCounter : setupPhaseState.setupStartCounter;
        this.endCounter = this.chartMode === ChartMode.EditDowntime ? this.config.data?.endCounter : setupPhaseState.setupEndCounter;

        this.orderSetupPhaseState = setupPhaseState;
      });
  }

  public editSetup(): void {
    this.productionOrderDsService
      .editProductionOrderSetupPhase(
        moment.utc(this.startTime).toISOString(),
        moment.utc(this.endTime).toISOString(),
        this.quantity,
        this.startCounter,
        this.endCounter,
        this.productionOrderId
      )
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        })
      )
      .subscribe({
        complete: () => {
          LogService.success('SUCCESS_MESSAGE.EDIT_SETUP');
          this.ref.close(true);
        }
      });
  }

  public updateSplitDownTime(): void {
    this.downtimeDsService
      .splitDowntime(moment.utc(this.splitTime).toISOString(), this.downtimeId, this.workCenterId)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        })
      )
      .subscribe({
        complete: () => {
          LogService.success('SUCCESS_MESSAGE.SPLIT_DOWNTIME');
          this.ref.close(true);
        }
      });
  }

  public editSetupApproval(): void {
    this.approveDsService
      .editApprovalReportSetupPhase(
        moment.utc(this.startTime).toISOString(),
        moment.utc(this.endTime).toISOString(),
        this.quantity,
        this.waste,
        this.maculature,
        this.startCounter,
        this.endCounter,
        this.approvalReportLineId,
        this.approvalReportDate,
        this.workCenterId
      )
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        })
      )
      .subscribe({
        complete: () => {
          LogService.success('SUCCESS_MESSAGE.EDIT_SETUP_APPROVAL');
          this.ref.close(true);
        }
      });
  }

  public onWasteChanged(): void {
    if (this.quantity.value === 0) {
      this.waste.value = 0;
      this.maculature.value = 0;
    }
    if (this.waste.value > this.quantity.value) {
      this.waste = this.quantity;
      this.maculature.value = 0;
    } else if (this.waste.value < 0) {
      this.waste.value = 0;
      this.maculature = this.quantity;
    } else {
      this.maculature.value = this.quantity.value - this.waste.value;
    }
    this.formGroup.controls.maculature.setValue(this.maculature.value);
    this.formGroup.controls.waste.setValue(this.waste.value);
  }

  public onMaculatureChanged(): void {
    if (this.quantity.value === 0) {
      this.waste.value = 0;
      this.maculature.value = 0;
    }
    if (this.maculature.value > this.quantity.value) {
      this.maculature = this.quantity;
      this.waste.value = 0;
    } else if (this.maculature.value < 0) {
      this.maculature.value = 0;
      this.waste = this.quantity;
    } else {
      this.waste.value = this.quantity.value - this.maculature.value;
    }
    this.formGroup.controls.maculature.setValue(this.maculature.value);
    this.formGroup.controls.waste.setValue(this.waste.value);
  }

  public setupQuantityChanged(): void {
    if (this.isEndTimeChanging) {
      return;
    }
    const setupQuantityEnter = this.quantity;

    for (let j = this.setupQuantityData.length - 1; j >= 0; j--) {
      if (
        this.setupQuantityData[j].value * this.counterCoefficient - this.startCounter ===
        setupQuantityEnter.value * this.counterCoefficient
      ) {
        this.endTime = new Date(this.setupQuantityData[j].timestamp);
        this.endCounter = this.startCounter + setupQuantityEnter.value * this.counterCoefficient;
        break;
      } else if (
        this.setupQuantityData[j].value * this.counterCoefficient - this.startCounter <
        setupQuantityEnter.value * this.counterCoefficient
      ) {
        if (j !== this.setupQuantityData.length - 1) {
          const priorPoint = this.setupQuantityData[j];
          const nextPoint = this.setupQuantityData[j + 1];
          const priorQuantity = priorPoint.value * this.counterCoefficient - this.startCounter;
          const nextQuantity = nextPoint.value * this.counterCoefficient - this.startCounter;
          const factor = (setupQuantityEnter.value * this.counterCoefficient - priorQuantity) / (nextQuantity - priorQuantity);
          const priorTimeInMs = new Date(priorPoint.timestamp).getTime();
          const nextTimeInMs = new Date(nextPoint.timestamp).getTime();
          const searchTimeInMs = priorTimeInMs + (nextTimeInMs - priorTimeInMs) * factor;
          this.endTime = new Date(searchTimeInMs);
          this.endCounter = this.startCounter + setupQuantityEnter.value * this.counterCoefficient;
        } else {
          this.endTime = new Date(this.setupQuantityData[j].timestamp);
          this.endCounter = this.setupQuantityData[j].value * this.counterCoefficient;
          this.quantity.value = this.setupQuantityData[j].value * this.counterCoefficient - this.startCounter;
        }
        break;
      }
    }
    if (this.isEndTimeBeforeStartTime(this.startTime, this.endTime) || this.endCounter < this.startCounter) {
      this.endTime = this.startTime;
      this.endCounter = this.startCounter;
    }

    this.formGroup.controls.setupQuantity.setValue(this.quantity.value);
    if (this.quantity.value === 0) {
      this.waste.value = 0;
      this.maculature.value = 0;
    } else {
      this.waste.value = this.quantity.value - this.maculature.value;
    }
    if (this.waste.value < 0) {
      this.waste.value = 0;
    }
    if (this.maculature.value > this.quantity.value) {
      this.maculature.value = this.quantity.value - this.waste.value;
    }
    console.info(
      `Edit setup: NewSetupQuantity='${this.quantity}',
      NewWaste='${this.formGroup.controls.waste.value}',
      NewMaculature='${this.formGroup.controls.maculature.value}'`
    );
    this.formGroup.controls.waste.setValue(this.waste.value);
  }

  public startTimeChanged(timeStamp: Date, onlyDay: boolean): void {
    if (onlyDay) {
      const selectedDay = timeStamp.getDate();
      const selectedMonth = timeStamp.getMonth();
      const selectedYear = timeStamp.getFullYear();

      this.startTime.setDate(selectedDay);
      this.startTime.setMonth(selectedMonth);
      this.startTime.setFullYear(selectedYear);
      this.startTime = new Date(this.startTime);
    } else {
      this.startTime = timeStamp;
    }
    this.initialStartTime = this.clearTimeToMinutes(this.initialStartTime);
    this.quantity.value = this.initialQuantity;
    this.startTime = this.clearTimeToMinutes(this.startTime);

    if (this.earliestLimit != null) {
      if (this.isStartTimeBeforeEarliestLimit(this.startTime, this.earliestLimit)) {
        this.startTime = moment(this.earliestLimit).toDate();
      }
    }
    this.startTime = this.isStartTimeNotAfterEndTime(this.startTime, this.endTime) ? moment(this.endTime).toDate() : this.startTime;

    this.startCounter = this.calculateCounter(this.startTime);
    if (this.startCounter < this.earliestSetupStartCounter) {
      this.startCounter = this.earliestSetupStartCounter;
      this.startTime = new Date(this.earliestLimit);
    }

    this.calculateSetupQuantity();
    this.isCalSetupQuant = true;
  }

  public endTimeChanged(timeStamp: Date, onlyDay: boolean): void {
    if (onlyDay && this.endTime) {
      const selectedDay = timeStamp.getDate();
      const selectedMonth = timeStamp.getMonth();
      const selectedYear = timeStamp.getFullYear();

      this.endTime.setDate(selectedDay);
      this.endTime.setMonth(selectedMonth);
      this.endTime.setFullYear(selectedYear);
      this.endTime = new Date(this.endTime);
    } else {
      this.endTime = timeStamp;
    }

    this.initialEndTime = this.clearTimeToMinutes(this.initialEndTime);
    this.quantity.value = this.initialQuantity;

    this.endTime = this.clearTimeToMinutes(this.endTime);
    this.endTime = this.isEndTimeBeforeStartTime(this.startTime, this.endTime) ? moment(this.startTime).toDate() : new Date(this.endTime);

    if (this.latestLimit != null) {
      this.endTime = this.isEndTimeAfterLatestLimit(this.endTime, this.latestLimit)
        ? moment(this.latestLimit).toDate()
        : new Date(this.endTime);
    }
    this.isEndTimeChanging = true;
    this.endCounter = this.calculateCounter(this.endTime);

    if (this.endCounter < this.startCounter) {
      this.endCounter = this.startCounter;
    }

    this.calculateSetupQuantity();
    this.isCalSetupQuant = true;
    this.isEndTimeChanging = false;
  }

  public splitTimeChanged(timeStamp: Date, onlyDay: boolean): void {
    if (onlyDay) {
      const selectedDay = timeStamp.getDate();
      this.splitTime.setDate(selectedDay);
      this.splitTime = new Date(this.splitTime);
    } else {
      this.splitTime = timeStamp;
    }
    this.splitTime = this.isEndTimeBeforeStartTime(this.startTime, this.splitTime) ? moment(this.startTime).toDate() : this.splitTime;

    this.splitTime = this.isSplitTimeAfterEndTime(this.splitTime, this.endTime) ? moment(this.endTime).toDate() : this.splitTime;
    this.formGroup.controls.splitDateTime.setValue(this.splitTime);
  }

  private getQuantityValue(): void {
    if (this.endCounter && this.counterCoefficient && this.startCounter) {
      this.quantity.value = (this.endCounter - this.startCounter) / this.counterCoefficient;
    }
  }

  private calculateCounter(timeValue: Date): number {
    if (this.setupQuantityData.length > 0 && this.setupQuantityData !== null) {
      const time = moment.utc(timeValue).toISOString();
      for (let j = 0; j < this.setupQuantityData.length; j++) {
        if (this.setupQuantityData[j].timestamp >= time) {
          if (j === 0 || this.setupQuantityData[j].timestamp === time) {
            return this.setupQuantityData[j].value * this.counterCoefficient;
          } else {
            return this.setupQuantityData[j - 1].value * this.counterCoefficient;
          }
        }
      }
      return this.setupQuantityData[this.setupQuantityData.length - 1].value * this.counterCoefficient;
    }
  }

  public calculateSetupQuantity(): void {
    console.info(
      `Edit setup: OriginalSetupQuantity='${this.formGroup.controls.setupQuantity.value}'
       OriginalWaste='${this.formGroup.controls.waste.value}',
       OriginalMaculature='${this.formGroup.controls.maculature.value}'`
    );
    this.getQuantityValue();

    this.quantity.value = Math.max(0, this.quantity.value);
    this.formGroup.controls.setupQuantity.setValue(this.quantity.value);

    if (this.quantity.value === 0) {
      this.waste.value = 0;
      this.maculature.value = 0;
    } else {
      this.waste.value = Math.max(0, this.quantity.value - this.maculature.value);
    }

    if (this.maculature.value > this.quantity.value) {
      this.maculature.value = this.quantity.value - this.waste.value;
    }

    this.formGroup.controls.waste.setValue(this.waste.value);
    this.formGroup.controls.maculature.setValue(this.maculature.value);

    console.info(
      `Edit setup: NewSetupQuantity='${this.quantity}',
      NewWaste='${this.formGroup.controls.waste.value}',
      NewMaculature='${this.formGroup.controls.maculature.value}'`
    );
  }

  // reset the form
  public editDowntimeReset(): void {
    LoadingNotificationService.publish(this.LOADING_TOPIC_EDITDOWNTIME, true);
    this.downtimeDsService
      .revertEditDowntime(this.downtimeId, this.workCenterId)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC_EDITDOWNTIME, false);
          this.ref.close(true);
        })
      )
      .subscribe(
        () => {
          this.startTime = this.initialStartTime;
          this.endTime = this.initialEndTime;
          this.quantity.value = this.initialQuantity;
          this.startCounter = this.initialStartCounter;
          this.endCounter = this.initialEndCounter;
          this.formGroup.controls.endDate.setValue(this.endTime);
          this.formGroup.controls.setupQuantity.setValue(this.quantity.value);
          console.info('reset edit downtime in machine chart dialog');
        },
        (error) => {
          console.error('reset edit downtime in machine chart dialog failed', error);
        }
      );
  }

  // update edit downtime
  public updateEditDowntime(): void {
    this.downtimeDsService
      .editDowntime(
        this.downtimeId,
        this.workCenterId,
        moment.utc(this.startTime).toISOString(),
        this.startCounter,
        moment.utc(this.endTime).toISOString(),
        this.endCounter,
        this.quantity
      )
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        })
      )
      .subscribe({
        complete: () => {
          LogService.success('SUCCESS_MESSAGE.EDIT_DOWNTIME');
          this.ref.close(true);
          console.info('edit downtime post success');
        }
      });
  }

  private commonFieldsForDowntime(data: IMachineChartDialogConfiguration) {
    this.downtimeId = data.downTimeId;

    this.isDisplayChart = this.isChartDisplayed();

    switch (this.chartMode) {
      case ChartMode.SplitDowntime:
        this.chartName = ChartType.splitDowntime;
        break;
      case ChartMode.EditDowntime:
        this.chartName = ChartType.editDowntime;
        break;
      default:
        this.chartName = ChartType.editSetup;
    }
  }

  private isChartDisplayed(): boolean {
    switch (this.chartMode) {
      case ChartMode.EditSetup:
        return true;
      case ChartMode.EditSetupApprove:
        return true;
      case ChartMode.SplitDowntime:
        if (this.endTime.getTime() === new Date(0).getTime()) {
          // TODO: how to check for null end time?
          return true;
        }

        return (this.endTime.getTime() - this.startTime.getTime()) / (60 * 1000) >= 5;
      case ChartMode.EditDowntime:
        return true;
    }

    return false;
  }

  private isStartTimeNotAfterEndTime = (start: Date, end: Date) => (start && end ? start > end : true);

  private isEndTimeBeforeStartTime = (start: Date, end: Date) => (start && end ? end < start : true);

  private isSplitTimeAfterEndTime = (splitTime: Date, endtime: Date) => (splitTime && endtime ? splitTime > endtime : true);

  private isStartTimeBeforeEarliestLimit = (start: Date, earliestLimit?: Date) => (start && earliestLimit ? start <= earliestLimit : true);

  private isEndTimeAfterLatestLimit = (end: Date, latestLimit: Date) => (end && latestLimit ? end > latestLimit : true);

  private isSubmitDisabled() {
    if (!this.isDisplayChart && this.chartMode === ChartMode.SplitDowntime) {
      this.submitDisabled = true;
    }
  }
}
