<section class="chronos-dialog--size-xs">
  <lib-container-pallet-template [textLabel]="'CREATE_PALLET_MODAL.LABEL' | translate">
    <lib-material-status [article]="article" [title]="'CREATE_PALLET_MODAL.ACTIVE_MATERIAL' | translate"></lib-material-status>
    <lib-pallet-number *libContainerPalletKpi [palletNumber]="sequenceNumber" [classification]="article.classification"></lib-pallet-number>
    <lib-pallet-kpi
      *libContainerPalletKpi
      [kpiLabel]="'CREATE_PALLET_MODAL.SSCC' | translate"
      [kpiValue]="ssccCode"
      [leftAligned]="true"
      [isSsccCodeShort]="true"
    ></lib-pallet-kpi>
    <lib-pallet-kpi
      *libContainerPalletKpi
      [kpiLabel]="'CREATE_PALLET_MODAL.QUANTITY' | translate"
      [kpiValue]="quantity | formatQuantity : false"
      [unit]="quantity.unitId"
      [leftAligned]="true"
      [emphasizeText]="true"
    ></lib-pallet-kpi>
    <lib-pallet-quantity-calculation
      *libContainerPalletForm
      [formControl]="sheetForm"
      [formUnitLabel]="'CREATE_PALLET_MODAL.QUANTITY' | translate"
      [article]="article"
      [bomUnitId]="quantity.unitId"
    ></lib-pallet-quantity-calculation>
  </lib-container-pallet-template>
  <lib-modal-footer
    [submitLabel]="submitButtonLabel"
    [submitDisabled]="sheetForm.invalid"
    [whenLoadingTopic]="LOADING_TOPIC"
    (submitClicked)="submitButton()"
    (declineClicked)="cancelButton()"
  ></lib-modal-footer>
</section>
